import React, {useContext} from 'react';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Layout from '../../Components/ProfileLayout';
import OrderTable from '../../Components/OrderTable';
import ORDER_TABLE_TYPES from '../../Components/OrderTable/orderTableTypes';

export default function MonthlyManage(props) {
  const app = useContext(Context);
  const pathname = props.location.pathname;

  if (!app.state.profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <Layout pathname={pathname}>
        {({selected}) => {
          switch (selected.name) {
            case 'monthlyRecord':
              return <OrderTable type={ORDER_TABLE_TYPES.MONTHLY} />;

            default:
              return null;
          }
        }}
      </Layout>
    </ContentWrapper>
  );
}
